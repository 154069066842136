// extracted by mini-css-extract-plugin
export var con = "detail-module--con--1EFif";
export var rightBox = "detail-module--rightBox--3r_er";
export var slideCon = "detail-module--slideCon--2GkBa";
export var slideChild = "detail-module--slideChild--13KRv";
export var pageSlideCon = "detail-module--pageSlideCon--1MUG8";
export var pageSlideCon2 = "detail-module--pageSlideCon2--crjZh";
export var tag = "detail-module--tag--KB6ed";
export var classInfo = "detail-module--classInfo--3r943";
export var classInfoBox = "detail-module--classInfoBox--3V1u8";
export var detail_title = "detail-module--detail_title--3SbMf";
export var detail_intro = "detail-module--detail_intro--2aTz2";