import React, { useState, useEffect, useContext, useCallback } from "react"
import * as styles from "./detail.module.css"
import Layout from "components/common/layout.js"
import DetailHeader from "components/post/detailHeader"
import couponNoteIcon from "images/couponNoteIcon.png"
import api from "apis"
import Cardinfo from "components/story/cardInfo"
import modalContext from "context/modalContext"
import queryString from "query-string"
import smallShare from "images/smallShare.png"
import { smalloffFavor, smallonFavor } from "images"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { getClassRoomListAsync } from "store/modules/classRoomReducer.js"
import { storyCategory, table } from "utils/data.js"
import { navigate } from "gatsby-link"
import {
  getDateYMD,
  textEdit,
  titleEdit,
  getEpisode,
  getThumb,
  onShare,
  customCount,
  getNewTag,
  onLike,
} from "utils"
import InputTitle from "components/tools/InputTitle.js"
import Slider from "components/tools/slide/Slide.js"
import Story from "components/story/story"
import SlidePagination from "components/tools/slide/slidePagination.js"
import NewTag from "components/tools/newTag"
import Like from "components/tools/Like"
import dompurify from "dompurify"
const Detail = props => {
  const dispatch = useDispatch()
  const getClassRoomList = useCallback(
    (currentPage, pagePostSize, order) =>
      dispatch(getClassRoomListAsync(currentPage, pagePostSize, order)),
    [dispatch]
  )

  const { classRoomList, total } = useSelector(
    ({ classRoomReducer }) => classRoomReducer,
    shallowEqual
  )

  console.log(classRoomList)

  const { talk_no, currentPage, pagePostSize } = queryString.parse(
    props.location.search
  )
  // example / http://localhost:8000/room/detail/?talk_no=1001

  const [slidePage, setSlidePage] = useState(Number(currentPage))
  const [post, setPost] = useState({})
  const [associatedStory, setAssociatedStory] = useState([])
  const [newTag, setNewTag] = useState(false)

  const { main, sub } = storyCategory

  useEffect(() => {
    ;(async () => {
      const post = await api.detailedRoominfo(talk_no)
      console.log(post)
      const associatedStory = await api.associatedStory(talk_no, table.get(11))
      console.log(associatedStory)
      // if (!post || !associatedStory) navigate("/class/room/list")
      setAssociatedStory(associatedStory)
      if (post) {
        setPost({
          writer: {
            belong: post.EduTalkWriter.belong,
            name: post.EduTalkWriter.name,
            img: getThumb(post.EduTalkWriter.thumb_title_uuid),
            introText: post.EduTalkWriter.introText,
          },
          profile: {
            icon_no: post.Profile.icon_no,
            name: post.Profile.name,
            uuid: post.Profile.uuid,
          },
          img: getThumb(post.thumb_title_uuid),
          title: titleEdit(post.title),
          bodyText: post.bodyText,
          intro: textEdit(post.introText),
          time: getDateYMD(post.publishTime, "YYYY.MM.DD"),
          Like: post?.Like,
          like: post?.like,
          hit: post?.hit,
        })
      }
    })()
  }, [talk_no, currentPage, pagePostSize])

  const { setmodalOpen, setModalMain } = useContext(modalContext)

  // const getNewTag = registerTime => {
  //   const time = getDateYMD(registerTime, "YYYY.MM.DD")
  //   const date = time.split(".")
  //   const postDate = new Date(date[0], date[1] - 1, date[2])
  //   console.log(date)
  //   console.log(postDate)
  //   const currentDate = new Date()
  //   const betweenTime = Math.abs(postDate.getTime() - currentDate.getTime())
  //   const result = Math.floor(betweenTime / (1000 * 60 * 60 * 24))

  //   if (result <= 7) {
  //     setNewTag(true)
  //   } else {
  //     setNewTag(false)
  //   }
  // }

  return (
    <Layout>
      <Cardinfo />
      <DetailHeader
        left={
          <div>
            <img
              src={post?.img}
              style={{
                minWidth: 350,
                maxWidth: 350,
                height: 350,
              }}
            />
          </div>
        }
        right={
          <div className={styles.rightBox}>
            <div className={styles.tag}>
              <div>특수교사 칼럼</div>

              <NewTag registerTime={post?.time} />
            </div>

            <div className={styles.detail_title}>{post?.title}</div>

            <div
              className={styles.detail_intro}
              // style={{
              //   fontSize: "15px",
              //   paddingTop: "10px",
              // width: "270px",
              //   width: "495px",
              //   textAlign: "left",
              //   minHeight: "73px",
              //   maxHeight: "100px",
              //   fontWeight: "normal",
              // }}
            >
              {post?.intro}
            </div>

            <div
              style={{
                fontSize: "15px",
                paddingTop: "10px",
                width: "270px",
                textAlign: "left",
                display: "flex",
                marginTop: "20px",
              }}
            >
              <img
                src={post?.writer?.img}
                alt={"titleLogo"}
                style={{ width: "45px", height: "45px", borderRadius: "45px" }}
              />
              <div
                style={{
                  placeSelf: "center",
                  fontSize: "14px",
                  color: "#6F6F6F",
                  marginLeft: "10px",
                  fontWeight: "normal",
                  textAlign: "left",
                }}
              >
                {post?.writer?.name}
              </div>
              <div
                style={{
                  placeSelf: "center",
                  fontSize: "14px",
                  marginLeft: "10px",
                  fontWeight: "normal",
                  color: "#b5b5b5",
                  textAlign: "left",
                }}
              >
                {post?.time}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                fontSize: "16px",
                fontWeight: "400",
                justifyContent: "right",
                marginTop: "75px",
                gap: "16px",
              }}
            >
              <div>view {post?.hit}</div>
              {/* <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={post?.Like ? smallonFavor : smalloffFavor}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={e => {
                    onLike(e, talk_no, table.get(6))
                    // onLike(e, talk_no, table.get(11))
                    // onLike(e, talk_no, "room")
                  }}
                  width="20px"
                  height="20px"
                />
                s<div style={{ marginLeft: "5px" }}>{post?.like}</div>
              </div> */}
              <Like
                on={smallonFavor}
                off={smalloffFavor}
                keyword={"talk"}
                isLike={post?.Like}
                style={{
                  img: { width: "20px", height: "20px" },
                  count: { marginLeft: "5px" },
                }}
                postNumber={talk_no}
                count={post?.like}
              />
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={smallShare}
                  onClick={() => {
                    onShare(setmodalOpen, setModalMain)
                  }}
                  width="20px"
                  height="20px"
                />
              </div>
            </div>
          </div>
        }
      >
        <div className={styles.con}>
          {typeof window !== "undefined" ? (
            <div
              dangerouslySetInnerHTML={{
                __html: dompurify.sanitize(post?.bodyText),
              }}
            />
          ) : null}
        </div>
        <div className={styles.classInfo}>
          <InputTitle
            name={"글쓴이 소개"}
            titleSize={22}
            style={{
              marginBottom: 30,
            }}
          />
          <div className={styles.classInfoBox}>
            <img
              src={post?.writer?.img}
              alt={"titleLogo"}
              width="220px"
              height="220px"
            />
            <div>
              <p>
                <span
                  style={{
                    fontWeight: "600",
                    fontSize: "18px",
                    color: "#010000",
                  }}
                >
                  {post?.writer?.name}
                </span>
                <span
                  style={{
                    fontWeight: "normal",
                    fontSize: "18px",
                    color: "#333333",
                  }}
                >
                  {" "}
                  {` | ${post?.writer?.belong}`}
                </span>
              </p>
              <p
                style={{
                  fontSize: "18px",
                  marginTop: "20px",
                  fontWeight: "normal",
                  color: "#333",
                }}
              >
                {post?.writer?.introText}
              </p>
            </div>
          </div>
        </div>
      </DetailHeader>

      <div
        style={{
          width: "850px",
          margin: "auto auto 60px auto",
        }}
      >
        <InputTitle
          name={"함께 보면 좋은 스토리"}
          titleSize={22}
          style={{
            marginBottom: 30,
            marginTop: 75,
            marginLeft: 27,
          }}
        />

        <div className={styles.slideCon}>
          <Slider name={"associated"}>
            {associatedStory?.map(post => (
              <Story
                registerTime={post.publishTime}
                key={post.story_no}
                story_no={post.story_no}
                title={post.title}
                introText={post.introText}
                thumb_title_uuid={post.thumb_title_uuid}
                cateMain={post.cateMain}
                cateSub={post.cateSub}
                Like={post.Like}
                free={post.free}
              />
            ))}
          </Slider>
        </div>
      </div>
      <div className={styles.pageSlideCon2}>
        <SlidePagination
          total={total}
          currentPage={slidePage}
          setCurrentPage={setSlidePage}
          postSize={pagePostSize}
          getlist={getClassRoomList}
          viewAll={() => {
            navigate("/class/room/list")
          }}
          title={"피치서가 선생님들이 들려주는 교실이야기"}
          line={true}
        >
          <div className={styles.pageSlideCon}>
            {classRoomList.map((post, i) => (
              <div
                key={post.talk_no}
                onClick={() => {
                  navigate(
                    `/class/room/detail/?talk_no=${post.talk_no}&currentPage=${currentPage}&pagePostSize=${pagePostSize}`
                  )
                }}
                style={{
                  fontWeight: Number(talk_no) === post.talk_no ? "700" : "400",
                }}
              >
                <div>
                  <div>
                    {customCount(getEpisode(total, slidePage, pagePostSize, i))}
                    화
                  </div>
                  <div>{post.title}</div>
                </div>
                <div>{post.hit} 읽음</div>
              </div>
            ))}
          </div>
        </SlidePagination>
      </div>
    </Layout>
  )
}

export default Detail
